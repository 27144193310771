var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { assertConnection } from './BlobService';
export function addScoreToNote(meetingName, score) {
    return __awaiter(this, void 0, void 0, function* () {
        const { bee } = assertConnection();
        const text = `Sonde Mental Health Score: ${score}`;
        bee.api
            .invoke('raam.addScoreToPatron', meetingName, text)
            .catch((e) => {
            console.error('Failed to save score to note:', e.message);
        });
    });
}
