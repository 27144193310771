var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { waitForInvocationReaction } from 'raam-client-lib';
import { assertConnection } from './BlobService';
export function getOrRegisterUser(user) {
    return __awaiter(this, void 0, void 0, function* () {
        return 'testId';
        // const sondeUserId = localStorage.getItem(SONDE_USER_ID);
        // if (sondeUserId) {
        //   return sondeUserId;
        // }
        // const { bee } = assertConnection();
        // return bee.api.invoke('sonde.registerUser', user);
    });
}
export function createBlob() {
    return __awaiter(this, void 0, void 0, function* () {
        const { bee } = assertConnection();
        return bee.api.invoke('sonde.createBlob').then((res) => {
            const { id } = res;
            return id;
        });
    });
}
export function getVoiceFeatureScore(sondeUserId, blobId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { bee } = assertConnection();
        return bee.actions
            .invoke('sonde.getVoiceFeatureScore', sondeUserId, blobId)
            .then(waitForInvocationReaction(bee, (r) => r.details.message));
    });
}
