var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getWaveBlob } from 'webm-to-wav-converter';
import { assertConnection, uploadTrack } from './BlobService';
import { createBlob, getOrRegisterUser, getVoiceFeatureScore, } from './SondeService';
export default function useAudioBlob() {
    const connection = assertConnection();
    const { bee } = connection;
    const downloadTrack = (blobID) => __awaiter(this, void 0, void 0, function* () {
        try {
            const blob = yield bee.blob.download(blobID);
            const audioBlob = new Blob([blob.content], {
                type: blob.contentType,
            });
            const blobUrl = URL.createObjectURL(audioBlob);
            return blobUrl;
        }
        catch (e) {
            console.error('Error downloading logo: \n', e);
        }
    });
    const uploadAudioTrack = (tracks) => __awaiter(this, void 0, void 0, function* () {
        if (tracks.length === 0) {
            throw new Error('Invalid tracks');
        }
        const userIdentifier = yield getOrRegisterUser({
            yearOfBirth: 1987,
            gender: 'OTHER',
            language: 'EN',
            device: {
                type: 'OTHER',
                manufacturer: 'something',
            },
        });
        const blobId = yield createBlob();
        if (!blobId) {
            throw new Error('Could bot create blob');
        }
        let blob = new Blob(tracks, {
            type: tracks[0].type,
        });
        blob = yield getWaveBlob(blob, false);
        yield uploadTrack(blob, blobId);
        yield new Promise((resolve) => setTimeout(resolve, 1000));
        // For debugging...
        // Note you will need to add `#roles.everyone` in the second `[]` of the
        // system:comb.upload of the `Honey-Sonde/defines.honey` file and update
        // the app in MHC.
        // await downloadTrack(blobId);
        return getVoiceFeatureScore(userIdentifier, blobId);
    });
    return { uploadAudioTrack, downloadTrack };
}
