import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, Container, Grid, Typography, } from '@material-ui/core';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { useWrappedTranslation } from '../../utils/i18nService';
import { useDeviceOrientation } from '../DeviceOrientationProvider';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
export default function PreJoinScreens() {
    const t = useWrappedTranslation();
    const { isConnecting } = useVideoContext();
    const { isFetching } = useAppState();
    const { isPortrait } = useDeviceOrientation();
    return (_jsx(Box, { display: 'flex', flex: 1, bgcolor: 'rgb(40, 42, 43)', id: 'twilio-container', children: isFetching || isConnecting ? (_jsx(Container, { maxWidth: 'xs', style: { height: '100%' }, children: _jsx(Grid, { container: true, alignItems: 'center', style: { height: '100%' }, children: _jsx(Grid, { item: true, xs: true, children: _jsx(Box, { bgcolor: 'white', padding: 2, borderRadius: 8, boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)', children: _jsxs(Grid, { container: true, justifyContent: 'center', alignItems: 'center', direction: 'column', style: { height: '100%' }, children: [_jsx("div", { children: _jsx(CircularProgress, { variant: 'indeterminate' }) }), _jsx("div", { children: _jsx(Typography, { variant: 'body2', style: { fontWeight: 'bold', fontSize: '16px' }, children: t('PreJoinScreen') }) })] }) }) }) }) })) : (_jsx(Container, { maxWidth: 'sm', style: {
                flex: 1,
                display: 'flex',
                minWidth: '210px',
            }, children: _jsx("div", { style: {
                    maxHeight: isPortrait ? '100%' : '600px',
                    minHeight: '240px',
                    paddingTop: '24px',
                    paddingBottom: '24px',
                    height: '100%',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    display: 'flex',
                    flex: 1,
                }, children: _jsx(DeviceSelectionScreen, {}) }) })) }));
}
