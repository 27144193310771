var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import { raam } from 'raam-client-lib';
import { createContext, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { CAPTION_DISPLAY_TIMEOUT, DEFAULT_CAPTION_LANGUAGE_CODE, SELECTED_AUDIO_INPUT_KEY, } from '../../constants';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { addScoreToNote } from '../../utils/ScoreService';
import useAudioBlob from '../../utils/useAudioBlob';
import useCCToken from '../../utils/useCCToken';
import useDataTrack from '../../utils/useDataTrack';
import useIdentity from '../../utils/useIdentity';
import useRoomConfig from '../../utils/useRoomConfig';
import useSpeechRecognizer from '../../utils/useSpeechRecognizer';
import DataMessage from '../DataTrack/DataMessage';
const SONDE_CAPTURE_INTERVAL_MS = 15000;
const PREFERRED_AUDIO_MIMETYPES = ['audio/wave', 'audio/mp4', 'audio/webm'];
const PREFERRED_AUDIO_MIMETYPE = _.find(PREFERRED_AUDIO_MIMETYPES, (t) => MediaRecorder.isTypeSupported(t));
export const CaptionContext = createContext(null);
export function useCaption() {
    return useContext(CaptionContext);
}
export const CaptionProvider = ({ children }) => {
    const [captions, setCaptions] = useState({});
    const captionsRef = useRef({});
    const [locallyDisplayCaptions, setLocallyDisplayCaptions] = useState(false);
    const [message, setMessage] = useState('');
    const { room } = useVideoContext();
    const { room: roomName, isClinician } = useAppState();
    const roomConfig = useRoomConfig(roomName);
    const { ccToken, ccDisabled } = useCCToken(room === null || room === void 0 ? void 0 : room.name);
    const [languageCode, setLanguageCode] = useState((roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage) || DEFAULT_CAPTION_LANGUAGE_CODE);
    const [isAudioEnabled] = useLocalAudioToggle();
    const micSource = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const recognizer = useSpeechRecognizer(ccToken, languageCode, micSource, setMessage);
    const identity = useIdentity(room);
    const dataTrack = useDataTrack(room);
    const [mediaRecorder, setMediaRecorder] = useState();
    const { uploadAudioTrack } = useAudioBlob();
    const startRecording = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!PREFERRED_AUDIO_MIMETYPE) {
            return;
        }
        const stream = yield navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        });
        const recorder = new MediaRecorder(stream, {
            mimeType: PREFERRED_AUDIO_MIMETYPE,
        });
        recorder.onstart = () => console.log('Recorder onstart', recorder.state);
        recorder.onpause = () => console.log('Recorder onpause', recorder.state);
        recorder.onresume = () => console.log('Recorder onresume', recorder.state);
        recorder.onstop = () => console.log('Recorder onstop', recorder.state);
        // Refer to the explanation in the .then for uploadAudioTrack
        let ignoreNextData = false;
        let tracks = [];
        recorder.ondataavailable = (event) => {
            if (recorder.state !== 'recording' || ignoreNextData) {
                ignoreNextData = false;
                return;
            }
            recorder.pause();
            tracks.push(event.data);
            uploadAudioTrack(tracks)
                .then((results) => {
                console.log('Processed voice data', results);
                tracks = [];
                if (roomName) {
                    addScoreToNote(roomName, results[0].score);
                }
                // We need to restart so that the first item will have the valid headers.
                recorder.stop();
                ignoreNextData = true;
                recorder.start(SONDE_CAPTURE_INTERVAL_MS);
            })
                .catch((e) => {
                if (e.code === 422) {
                    console.log('Not enough data to process voice, resuming');
                }
                // Do not throw if something is wrong... So that it re-try later.
                recorder.resume();
            });
        };
        recorder.start(SONDE_CAPTURE_INTERVAL_MS);
        setMediaRecorder(recorder);
    }), [roomName]);
    const stopRecording = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        mediaRecorder === null || mediaRecorder === void 0 ? void 0 : mediaRecorder.stop();
        setMediaRecorder(undefined);
    }), [mediaRecorder]);
    useEffect(() => {
        if (isAudioEnabled && (roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participantsCaptioning.length)) {
            recognizer === null || recognizer === void 0 ? void 0 : recognizer.startContinuousRecognitionAsync();
            return;
        }
        recognizer === null || recognizer === void 0 ? void 0 : recognizer.stopContinuousRecognitionAsync();
    }, [recognizer, isAudioEnabled, roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participantsCaptioning]);
    const enqueueCaptionFn = useCallback((msg) => {
        setCaptions((prev) => {
            return Object.assign(Object.assign({}, prev), { [msg.source]: {
                    details: msg.details,
                    timeStamp: msg.timeStamp,
                } });
        });
        captionsRef.current = Object.assign(Object.assign({}, captionsRef.current), { [msg.source]: {
                details: msg.details,
                timeStamp: msg.timeStamp,
            } });
    }, []);
    useEffect(() => {
        if (!isAudioEnabled || !message || !identity) {
            return;
        }
        // Get the LocalDataTrack that we published to the room.
        const captionMessage = new DataMessage('captionData', identity, message, Date.now());
        dataTrack === null || dataTrack === void 0 ? void 0 : dataTrack.send(captionMessage);
        enqueueCaptionFn(captionMessage);
    }, [enqueueCaptionFn, isAudioEnabled, message, identity, dataTrack]);
    useEffect(() => {
        if (roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage) {
            setLanguageCode(roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage);
        }
    }, [roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage]);
    useEffect(() => {
        const interval = setInterval(() => {
            const nextCaptions = {};
            _.map(roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participants, (pid) => {
                const previousCaptionEntry = captionsRef.current[pid];
                if (previousCaptionEntry &&
                    previousCaptionEntry.timeStamp + CAPTION_DISPLAY_TIMEOUT > Date.now()) {
                    nextCaptions[pid] = previousCaptionEntry;
                }
                else {
                    nextCaptions[pid] = {
                        details: '',
                        timeStamp: Date.now(),
                    };
                }
            });
            setCaptions(nextCaptions);
            captionsRef.current = nextCaptions;
        }, 0.25 * CAPTION_DISPLAY_TIMEOUT);
        return () => {
            clearInterval(interval);
        };
    }, [roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participants]);
    const startRecordingRef = useRef(startRecording);
    const stopRecordingRef = useRef(stopRecording);
    // prevents dependency looping in next useEffect
    useEffect(() => {
        startRecordingRef.current = startRecording;
        stopRecordingRef.current = stopRecording;
    }, [startRecording, stopRecording]);
    useEffect(() => {
        if (room && !isClinician) {
            startRecordingRef.current();
            return () => {
                stopRecordingRef === null || stopRecordingRef === void 0 ? void 0 : stopRecordingRef.current();
            };
        }
    }, [room, isClinician, startRecordingRef, stopRecordingRef]);
    useEffect(() => {
        if (room) {
            const removeRemoteParticipant = (r) => {
                raam.twilio.removeRoomParticipant(room.name, r.identity);
                stopRecording();
            };
            room.on('participantDisconnected', removeRemoteParticipant);
            return () => {
                room.off('participantDisconnected', removeRemoteParticipant);
            };
        }
    }, [room, stopRecording]);
    useEffect(() => {
        if (room) {
            const removeMyself = () => {
                raam.twilio.removeRoomParticipant(room.name, identity);
            };
            room.on('disconnected', removeMyself);
            stopRecording();
            return () => {
                room.off('disconnected', removeMyself);
            };
        }
    }, [room, identity]);
    return (_jsx(CaptionContext.Provider, { value: {
            captions,
            enqueueCaptionFn,
            languageCode,
            locallyDisplayCaptions,
            updateLocallyDisplayCaptionsFn: setLocallyDisplayCaptions,
            ccDisabled,
        }, children: children }));
};
