var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ConnectionService, DeferredPromise, raam } from 'raam-client-lib';
import Resumable from 'resumablejs';
export function assertConnection() {
    let connection;
    ConnectionService.connection$
        .subscribe((conn) => {
        connection = conn;
    })
        .unsubscribe();
    if (!connection) {
        throw new Error('Connection is needed!');
    }
    return connection;
}
function getToken(connection) {
    if (!connection || !connection.token) {
        throw new Error('Invalid token!');
    }
    return connection.token.access_token || connection.token;
}
/**
 * @module BlobService
 */
/**
 * @memberof BlobService
 * @description Downloads an image from Hive's blob storage, if permitted
 * @param {String} documentId - The document blob storage id.
 * @param {HTMLImageElement} img - An optional image element to set it's src.
 * @returns {Promise} - A promise with the image object, if needed.
 */
export function downloadTrack(documentId) {
    return __awaiter(this, void 0, void 0, function* () {
        const connection = assertConnection();
        const url = raam.clinician.getBlobUrl(documentId, 'download');
        const token = getToken(connection);
        const response = yield fetch(url, {
            headers: { Authorization: `Token ${token}` },
        });
        const blob = yield response.blob();
        const object = window.URL.createObjectURL(blob);
        return object;
    });
}
export function uploadTrack(blob, blobId) {
    return __awaiter(this, void 0, void 0, function* () {
        const file = new File([blob], blobId);
        const connection = assertConnection();
        const contentType = blob.type;
        const token = getToken(connection);
        const url = raam.clinician.getBlobUrl(blobId, 'upload');
        const r = new Resumable({
            target: url,
            forceChunkSize: true,
            simultaneousUploads: 1,
            headers: {
                Authorization: `Token ${token}`,
            },
            // The auto-detect doesn't seem to work, so force resumableType as an extra query parameter if specified
            query: contentType ? { resumableType: contentType } : undefined,
        });
        r.addFile(file);
        const { promise, resolveFn, rejectFn } = DeferredPromise();
        r.on('fileAdded', () => r.upload());
        r.on('fileError', (_file, message) => rejectFn(message));
        r.on('complete', () => resolveFn());
        r.on('error', (message) => rejectFn(message));
        return promise;
    });
}
