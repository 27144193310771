import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import ParticipantsButton from "./components/Buttons/ParticipantsButton/ParticipantsButton";
import Caption from "./components/Caption/Caption";
import { useDeviceOrientation } from "./components/DeviceOrientationProvider";
import MainParticipant from "./components/MainParticipant/MainParticipant";
import MainParticipantInfoBox from "./components/MainParticipantInfoBox/MainParticipantInfoBox";
import Menu from "./components/MenuBar/Menu/Menu";
import MenuBar from "./components/MenuBar/MenuBar";
import Participant from "./components/Participant/Participant";
import MediaErrorSnackbar from "./components/PreJoinScreens/MediaErrorSnackbar/MediaErrorSnackbar";
import PreJoinScreens from "./components/PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from "./components/ReconnectingNotification/ReconnectingNotification";
import useSelectedParticipant from "./components/VideoProvider/useSelectedParticipant/useSelectedParticipant";
import useMainParticipant from "./hooks/useMainParticipant/useMainParticipant";
import useParticipants from "./hooks/useParticipants/useParticipants";
import useRoomState from "./hooks/useRoomState/useRoomState";
import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import theme from "./theme";
export default function App({ optionButtons }) {
    const roomState = useRoomState();
    const { setClientRef, clientDimension, clientComponent, isPortrait } = useDeviceOrientation();
    const mainParticipant = useMainParticipant();
    const participants = useParticipants();
    const [selectedParticipant] = useSelectedParticipant();
    const { room, getAudioAndVideoTracks, localTracksEventEmitter } = useVideoContext();
    const localParticipant = _.get(room, "localParticipant");
    const [mediaError, setMediaError] = useState();
    const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    useEffect(() => {
        return () => {
            localTracksEventEmitter.emit('disconnected');
        };
    }, [localTracksEventEmitter]);
    useEffect(() => {
        const handleResize = () => {
            setClientRef(clientComponent);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [setClientRef, clientComponent]);
    useEffect(() => {
        if (!mediaError) {
            getAudioAndVideoTracks().catch(error => {
                console.log("Error acquiring local media:");
                console.dir(error);
                setMediaError(error);
            });
        }
    }, [getAudioAndVideoTracks, mediaError]);
    // Here we would like the height of the main container to be the height of the viewport.
    // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
    // not the viewport. This looks bad when the mobile browsers location bar is open.
    // We will dynamically set the height with 'window.innerHeight', which means that this
    // will look good on mobile browsers even after the location bar opens or closes.
    // const height = useHeight();
    return (_jsxs("div", { ref: setClientRef, style: { position: "relative", display: "flex", flex: 1, height: "100%" }, children: [_jsx(MediaErrorSnackbar, { error: mediaError }), roomState === "disconnected" ? (_jsx(PreJoinScreens, {})) : (_jsxs(Box, { display: "flex", flex: 1, bgcolor: "rgb(40, 42, 43)", children: [_jsx(ReconnectingNotification, {}), _jsxs(Grid, { container: true, direction: "column", style: { position: "relative" }, children: [_jsx(Box, { position: "absolute", width: 0, height: 0, children: participants.map(participant => (_jsx(Participant, { participant: participant, isSelected: participant === selectedParticipant, hideParticipant: true }, participant.sid))) }), _jsx(Box, { position: "absolute", top: 0, left: 0, maxWidth: "25%", width: "350px", minWidth: "140px", maxHeight: "25%", height: "300px", minHeight: "180px", zIndex: 4, display: "flex", padding: 2, children: localParticipant && (_jsx(Participant, { participant: localParticipant, isLocalParticipant: true })) }), _jsx(Box, { position: "absolute", width: "100%", height: "100%", display: "flex", zIndex: 3, padding: 2, children: _jsxs(Grid, { container: true, direction: "column", justifyContent: "space-between", spacing: 2, children: [_jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, direction: isPortrait ? "column-reverse" : "row", justifyContent: "flex-end", alignItems: "flex-end", spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(ParticipantsButton, {}) }), optionButtons &&
                                                        _.map(optionButtons, optionButton => (_jsx(Grid, { item: true, children: optionButton }))), _jsx(Grid, { item: true, children: _jsx(Menu, { placement: isPortrait ? "left" : "bottom" }) })] }) }), _jsx(Grid, { item: true, style: {
                                                height: clientDimension.width >= theme.breakpoints.values.md
                                                    ? "54px"
                                                    : "auto"
                                            }, children: _jsxs(Grid, { container: true, alignItems: "flex-end", spacing: 2, children: [clientDimension.width >= theme.breakpoints.values.md && (_jsx(Grid, { item: true, xs: 4 })), _jsx(Grid, { item: true, xs: true, children: _jsx(Grid, { container: true, justifyContent: clientDimension.width >= theme.breakpoints.values.md
                                                                ? "center"
                                                                : "flex-start", children: _jsx(Grid, { item: true, style: { width: "100%", maxWidth: "300px" }, children: _jsx(MainParticipantInfoBox, { participant: mainParticipant }) }) }) }), _jsx(Grid, { item: true, xs: clientDimension.width < theme.breakpoints.values.md
                                                            ? "auto"
                                                            : 4, style: {
                                                            minWidth: clientDimension.width < theme.breakpoints.values.md
                                                                ? 0
                                                                : "195px"
                                                        }, children: _jsx(MenuBar, {}) })] }) })] }) }), _jsx(Grid, { item: true, xs: true, style: { display: "flex" }, children: _jsx(MainParticipant, {}) }), _jsx("div", { style: {
                                    position: "absolute",
                                    bottom: "5rem",
                                    zIndex: 100,
                                    padding: isMobileScreen ? "0 4rem 0 1rem" : "0 2rem",
                                    width: "100%"
                                }, children: _jsx(Caption, {}) })] })] }))] }));
}
